const bowser = require('bowser');
const body = document.body;

const info = bowser.getParser(window.navigator.userAgent).parsedResult;

const osName = info.os.name;
const browserName = info.browser.name;
const platform = info.platform.type;
const isMobile = platform === 'mobile';
const isTablet = platform === 'tablet';
const isDesktop = platform === 'desktop';

window.bowser = {
  browserName,
  osName,
  platform,
  isMobile,
  isTablet,
  isDesktop
};


body.className += ` ${osName} ${browserName}${isMobile || isTablet ? ' mobile' : ''}`;
