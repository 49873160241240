import { arrayByClass } from "utils/dom";

const carouselForeclosureInit = () => {
  const foreclosureFormContainer = $('.js-foreclosure-form-container');

  if (foreclosureFormContainer.length) {

    foreclosureFormContainer.on('afterChange', function(event, slick) {
      $('html, body').animate({
        scrollTop: 120
      });
    });

    foreclosureFormContainer.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: false,
      adaptiveHeight: true,
      swipe: false
    });

    arrayByClass(foreclosureFormContainer[0], 'js-form-move')
      .forEach(button => {
        button.addEventListener('click', function () {
          const stepTarget = +this.getAttribute('data-step-target');
          foreclosureFormContainer.slick('slickGoTo', stepTarget - 1);
        });
      });

    return {
      resize: () => foreclosureFormContainer.slick('setPosition')
    }
  }
};

export { carouselForeclosureInit };