import { addClass, removeClass } from 'utils/dom';
import { whichTransitionEvent } from 'utils/animation';

export class WeberryCollapse {
  constructor (elem, params) {

    if (elem.WeberryCollapse) {
      return elem.WeberryCollapse;
    }

    const defaultOptions = {
      animationProgressClass: 'is-collapse-progress',
      collapsedClass: 'is-collapsed',
      toggleData: 'data-open',
      handlerShow () {},
      handlerShown () {},
      handlerBeforeShow() {
        return true;
      },
      handlerHide () {},
      handlerHidden () {},
      handlerBeforeHide() {
        return true;
      },
      handlerDestroy () {}
    };

    const options = Object.assign({}, defaultOptions, params);

    this.elem = elem;
    this.options = options;
    this.isOpen = false;

    elem.WeberryCollapse = this;

    this.initialHeight = 0;
    this.lastHeight = null;

    this._transitionEvent = whichTransitionEvent();

    this._showTimeout = null;
    this._hideTimeout = null;

    this.showInProgress = false;
    this.hideInProgress = false;

    this.toggleElems = null;

    this._showTransitionFn = () => {
      elem.removeEventListener(this._transitionEvent, this._showTransitionFn);
      removeClass(elem, options['animationProgressClass']);
      addClass(elem, options['collapsedClass']);
      elem.style.height = '';
      elem.style.display = '';
      this.options.handlerShown();
    };

    this._hideTransitionFn = () => {
      elem.removeEventListener(this._transitionEvent, this._hideTransitionFn);
      removeClass(elem, options['animationProgressClass']);
      removeClass(elem, options['collapsedClass']);
      elem.style.height = '';
      elem.style.display = '';
      this.hideInProgress = false;
      this.options.handlerHidden();
    };

    this.init();
  }

  show () {
    const _self = this;
    if(_self.options.handlerBeforeShow() === false) {
      return;
    }
    const {elem, options, hideInProgress} = _self;

    clearTimeout(_self._hideTimeout);
    elem.removeEventListener(_self._transitionEvent, this._hideTransitionFn);

    _self.isOpen = true;
    _self.toggleElemsSetState(true);

    _self.options.handlerShow();

    if(!hideInProgress) {
      _self.initialHeight = this.calcInitialHeight();
      _self.lastHeight = this.calcHeight();
      // elem.style.height = '0px';
      elem.style.height = this.initialHeight;
      elem.style.display = 'block';
    } else {
      elem.style.height  = _self.lastHeight + 'px';
    }

    elem.addEventListener(this._transitionEvent, this._showTransitionFn);

    addClass(elem, options['animationProgressClass']);

    if(!hideInProgress) {
      _self._showTimeout = setTimeout(() => {
        elem.style.height = _self.lastHeight + 'px';
      }, 50);
    }

  }

  calcInitialHeight() {
    const height = getComputedStyle(this.elem).height;

    return isNaN(parseInt(height)) ? 0 : height;
  }

  hide () {
    const _self = this;
    if(_self.options.handlerBeforeHide() === false) {
      return;
    }
    const {elem, options} = _self;

    clearTimeout(_self._showTimeout);
    elem.removeEventListener(_self._transitionEvent, this._showTransitionFn);

    _self.hideInProgress = false;


    _self.isOpen = false;
    _self.toggleElemsSetState(false);

    _self.options.handlerHide();

    elem.style.height = elem.clientHeight + 'px';
    elem.style.display = 'block';

    elem.addEventListener(this._transitionEvent, this._hideTransitionFn);

    _self._hideTimeout = setTimeout(() => {
      addClass(elem, options['animationProgressClass']);
      elem.style.height = this.initialHeight;
      _self.hideInProgress = true;
    }, 50);
  }

  calcHeight () {
    const {elem} = this;
    elem.style.height = 'auto';
    elem.style.display = 'block';
    const height = elem.clientHeight;
    elem.style.display = '';

    return height;
  }

  toggle () {
    this.isOpen ? this.hide() : this.show();
  }

  toggleElemsInit () {
    const _self = this;

    const {elem, isOpen, options} = _self;

    let {toggleElems} = _self;

    const target = elem.getAttribute('id');

    toggleElems = Array.from(document.querySelectorAll(`[href="#${target}"],[data-target="#${target}"]`));

    const _toggleListener = (e) => {
      e.preventDefault();
      _self.toggle();
    };

    _self._toggleListener = _toggleListener;

    toggleElems.forEach(toggleElem => {
      toggleElem.setAttribute(options.toggleData, isOpen);

      toggleElem.addEventListener('click', _toggleListener);
    });

    this.toggleElems = toggleElems;

  }

  toggleElemsDestroy () {
    const _self = this;

    const {toggleElems, _toggleListener, options} = _self;

    toggleElems.forEach(toggleElem => {
      toggleElem.removeAttribute(options.toggleData);
      toggleElem.removeEventListener('click', _toggleListener);
    });
  }

  toggleElemsSetState (state) {
    const _self = this;
    const {options, toggleElems} = _self;

    toggleElems.forEach(toggleElem => {
      toggleElem.setAttribute(options.toggleData, state);
    });
  }

  init () {
    const _self = this;

    _self.toggleElemsInit();

  }

  destroy () {
    const _self = this;

    const {options, elem} = _self;

    _self.toggleElemsDestroy();

    clearTimeout(_self._showTimeout);
    clearTimeout(_self._hideTimeout);

    removeClass(elem, options.animationProgressClass);
    removeClass(elem, options.collapsedClass);

    elem.WeberryCollapse = null;

    options.handlerDestroy(elem);
  }

  reset() {
    this.toggleElemsSetState(false);
    this.open = false;
    clearTimeout(this._showTimeout);
    clearTimeout(this._hideTimeout);
    removeClass(this.elem, options.animationProgressClass);
    removeClass(this.elem, options.collapsedClass);
  }
}