export default {
  'xxs': 0,
  'xs': 320,
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xl': 1260,
  'fh': 1890,
  'xxs-max': 320 - 1,
  'xs-max': 576 - 1,
  'sm-max': 768 - 1,
  'md-max': 992 - 1,
  'lg-max': 1260 - 1,
  'xl-max': 1890 - 1
}