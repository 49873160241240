import './_app-common';

import 'plugins/jQuery';
import 'plugins/slick';

import 'modules/browserDetect';
// import 'bootstrap-custom/modal';

import '../sass/main.scss';
import {calcWindowHeight, onReady} from 'utils';
import {arrayByClass, addClass, removeClass} from "utils/dom";
import { initSelect2 } from "components/select";
import { carouselForeclosureInit } from "components/carousel";
import {WeberryCollapse} from "modules/collapse";
import breakpoints from 'utils/breakpoints';

import {modalSuccess, modalError} from 'components/modal';


const wbry_storage = {
  formSuccess: (form, context) => {
    form.reset();
    const elems = form.querySelectorAll('.js-form-input input, .js-form-input textarea');
    
    elems.forEach(el => {
      el.focus();
      el.blur();
    });
    
    modalSuccess.open();
  },
  formError: (form, context) => {
    modalError.open();
  }
};

window.wbry_storage = wbry_storage;

{
  onReady(() => {

    /* ---------------------------------index hero------------------------------------ */

    const wHeight = calcWindowHeight();
    const heroPrimaryContainer = document.querySelector('.js-hero-primary-container');
    if (heroPrimaryContainer && window.innerWidth >= breakpoints['xl']) {
      heroPrimaryContainer.style.height = wHeight + 'px';
    }

    /* ---------------------------------collapses------------------------------------ */

    arrayByClass(document,'js-collapsable')
      .forEach(collapseContainer => {
        const collapseElem = new WeberryCollapse(collapseContainer);
      });

    /* ---------------------------------form foreclosure carousel------------------------------------ */

    const formSlider = carouselForeclosureInit();

    /* ---------------------------------forms elements------------------------------------ */

    // inputs
    arrayByClass(document, 'js-form-input')
      .forEach(formInput => {
        formInput.querySelector('input, textarea').addEventListener('focus', function() {
          addClass(formInput, 'is-focused');
        });
        formInput.querySelector('input, textarea').addEventListener('blur', function() {
          removeClass(formInput, 'is-focused');
          if (this.value === '') addClass(formInput, 'is-empty'); else removeClass(formInput, 'is-empty');
        })
      });

    //selects
    initSelect2();

    //add-fields buttons

    arrayByClass(document, 'js-form-fields-add')
      .forEach(buttonAdd => {
        buttonAdd.addEventListener('click', function () {
          const prevField = buttonAdd.previousElementSibling;
          const newCount = +(prevField.querySelector('.js-form-input').getAttribute('data-count')) + 1;
          const newField = buttonAdd.previousElementSibling.cloneNode(true);
          this.parentElement.insertBefore(newField, buttonAdd);
          const newFieldLabel = newField.querySelector('.js-form-input');
          newFieldLabel.setAttribute('data-count', newCount);
          newFieldLabel.querySelector('.js-title').innerText = 'Other Known address (' + newCount + ')';
          const newFieldInput = newFieldLabel.querySelector('input, textarea');
          newFieldInput.addEventListener('focus', function() {
            addClass(newFieldLabel, 'is-focused');
          });
          newFieldInput.addEventListener('blur', function() {
            removeClass(newFieldLabel, 'is-focused');
            if (this.value === '') addClass(newFieldLabel, 'is-empty'); else removeClass(newFieldLabel, 'is-empty');
          });
          formSlider.resize();
        });
      });

    /* ---------------------------------load information page------------------------------------ */

    const hash = location.hash;
    if (document.querySelector('.js-information-collapse') && hash.length && hash[0] === '#') {
      const collapseList = document.getElementById(hash.slice(1));
      const thisWC = collapseList.querySelector('.js-collapsable').WeberryCollapse;
      thisWC && thisWC.show();
    }

    /* ---------------------------------navbar toggle sandwich collapse------------------------------------ */

    const navbarMenu = document.querySelector('.js-navbar-menu');

    const navbarMenuCollapseContainer = document.querySelector('.js-sandwich-collapse');
    const navbarMenuCollapse = new WeberryCollapse(navbarMenuCollapseContainer, {
      handlerShow() {
        if (window.innerWidth < breakpoints['md']) {
          navbarMenu.style.height = wHeight + 'px';
        }
      },
      handlerShown() {
        addClass(document.body, 'fixed');
        addClass(document.documentElement, 'fixed');
      },
      handlerHidden() {
        removeClass(document.body, 'fixed');
        removeClass(document.documentElement, 'fixed');
        if (window.innerWidth < breakpoints['md']) {
          navbarMenu.style.height = '';
        }
      }
    });

  });
}